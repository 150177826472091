
<template>
  <div class="resale" :class="{ mobile: isMobile }">
    <div class="top d-flex justify-space-between">
      <div v-if="!isMobile">
        <button class="filter_btn" @click="showFilter" v-if="!isShowFilter">
          <img
            class="img"
            width="13"
            height="15"
            style="margin-bottom: -2px"
            src="@/assets/icons/icon_filters.png"
          />
          <span class="filter">Filter</span>
        </button>
        <button class="filter_btn" @click="showFilter" v-if="isShowFilter">
          <img
            class="img"
            width="13"
            height="15"
            style="margin-bottom: -2px"
            src="@/assets/icons/icon_l.png"
          />
          <span class="filter">Filter</span>
        </button>
        <button class="clean_btn" @click="clearAll" v-if="isShowClear">
          <span class="ClearAll">Clear All</span>
        </button>
        <img
          v-if="!isMobile && isShowClear"
          class="clo"
          @click="closeDialog"
          src="@/assets/icons/btn_close.png"
          alt=""
        />
      </div>
      <div class="top-right d-flex flex-row">
        <div>
          <SearchInput
            class="search"
            style="margin-bottom: -20px"
            @SearchChange="SearchChange"
            placeholder="Search by NFT"
          />
        </div>
        <div class="d-flex flex-row mr-5 ml-11">
          <div
            v-if="!isMobile"
            class="bigView_btn"
            :class="{ bigView_btn_back: !bigCard }"
            @click="changeBigView"
          >
            <img
              v-if="bigCard"
              src="@/assets/icons/view_b.png"
              width="20px"
              height="20px"
              style="border-radius: 3px; margin-left: 20px; margin-top: 10px"
            />
            <img
              v-if="!bigCard"
              src="@/assets/icons/view_b1.png"
              width="20px"
              height="20px"
              style="
                border-radius: 3px;
                margin-left: 20px;
                margin-top: 10px;
                cursor: pointer;
              "
            />
          </div>
          <div
            v-if="!isMobile"
            class="smallView_btn"
            :class="{ smallView_btn_back: smallsmallCard }"
            @click="changeSmallView"
          >
            <img
              v-if="smallsmallCard"
              src="@/assets/icons/view_s.png"
              width="20px"
              height="20px"
              style="border-radius: 3px; margin-left: 16px; margin-top: 10px"
            />
            <img
              v-if="!smallsmallCard"
              src="@/assets/icons/view_s1.png"
              width="20px"
              height="20px"
              style="
                border-radius: 3px;
                margin-left: 16px;
                margin-top: 10px;
                cursor: pointer;
              "
            />
          </div>
        </div>
        <SortSelect
          v-if="!isMobile"
          :list="selectList2"
          v-model="activeSort2"
          style="width: 165px"
        />
        <img
          v-if="!isMobile"
          :class="[rotate ? 'go' : '']"
          class="refresh"
          src="@/assets/icons/icon_refresh.png"
          @click="refresh"
        />
      </div>
    </div>

    <div class="main d-flex flex-row">
      <div class="sort" v-if="isShowFilter && !isMobile">
        <div>
          <div
            class="selectTitle d-flex justify-space-between"
            @click="clickToken"
          >
            <div class="name">Tokens & Price</div>
            <v-icon color="primary" v-if="clickTokens">mdi-menu-down</v-icon>
            <v-icon color="primary" v-else>mdi-menu-up</v-icon>
          </div>
          <div v-if="!clickTokens" class="labstatus">
            <div class="tokens">
              <div
                class="lab"
                v-for="(item, index) in tokenList"
                :key="index"
                :class="item.checked ? 'active' : ''"
                @click="tagChange(item)"
              >
                <div class="d-flex flex-row" v-if="item.imgName">
                  <img
                    :src="require('@/assets/icons/' + item.imgName + '.png')"
                    width="22px"
                    height="22px"
                    style="margin-top: 7px"
                  />
                  <div class="chainname">{{ item.value }}</div>
                </div>
                <div class="" v-else>
                  <div class="chainnames">{{ item.value }}</div>
                </div>
              </div>
            </div>

            <div class="tokenPrice">
              <div class="title">Price</div>
              <div class="priceSelect d-flex flex-row">
                <input
                  onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,4})?).*$/g, '$1')"
                  v-model="minValue"
                  type="text"
                  placeholder="Min"
                  @input="minValueInput"
                />
                <div class="ml-2 mt-1 mr-2"><span class="to">to</span></div>
                <input
                  onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,4})?).*$/g, '$1')"
                  v-model="maxValue"
                  type="text"
                  placeholder="Max"
                />
                <button
                  class="apply ml-2"
                  :class="{ disable: inputTagList[0].value == 'All' || minValue == '' || maxValue == ''}"
                  @click="Apply"
                  :disabled="inputTagList[0].value == 'All' || minValue == ''  ||  maxValue == ''"
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="line"></div>

        <div>
          <div
            class="selectTitle d-flex justify-space-between"
            @click="clickItem1"
          >
            <div class="name">Status</div>
            <v-icon class="ml-2" color="primary" v-if="clickStatus"
              >mdi-menu-down</v-icon
            >
            <v-icon class="ml-2" color="primary" v-else>mdi-menu-up</v-icon>
          </div>
          <div v-if="!clickStatus" class="labstatus">
            <div class="tokens">
              <div
                class="lab d-flex flex-row"
                v-for="(item, index) in statusList"
                :key="index"
                :class="item.checked ? 'active' : ''"
                @click="statusChange(item)"
              >
                <div class="chainname">{{ item.value }}</div>
              </div>
            </div>
          </div>
          <div class="line"></div>
        </div>

        <div>
          <div
            class="selectTitle d-flex justify-space-between"
            @click="clickItem2"
          >
            <div class="name">Type</div>
            <v-icon class="ml-2" color="primary" v-if="clickType"
              >mdi-menu-down</v-icon
            >
            <v-icon class="ml-2" color="primary" v-else>mdi-menu-up</v-icon>
          </div>
          <div v-if="!clickType" class="labstatus">
            <div class="tokens">
              <div
                class="lab d-flex flex-row"
                v-for="(item, index) in typeList"
                :key="index"
                :class="item.checked ? 'active' : ''"
                @click="typeChange(item)"
              >
                <div class="chainname">{{ item.value }}</div>
              </div>
            </div>
          </div>
          <div class="line"></div>
        </div>
        <div>
          <div
            class="selectTitle d-flex justify-space-between"
            @click="clickItem3"
          >
            <div class="name">Verification</div>
            <v-icon class="ml-2" color="primary" v-if="clickQualification"
              >mdi-menu-down</v-icon
            >
            <v-icon class="ml-2" color="primary" v-else>mdi-menu-up</v-icon>
          </div>
          <div v-if="!clickQualification" class="checkBoxSelect">
            <div
              class="lab d-flex flex-row"
              v-for="(item, index) in QualificationList"
              :key="index"
            >
              <v-checkbox
                class="checkbox"
                :value="item.checked"
                @click="QualificationChange(item, index)"
              ></v-checkbox>
              <div class="name">{{ item.value }}</div>
            </div>
          </div>
          <div class="line"></div>
        </div>

        <div>
          <div
            class="selectTitle d-flex justify-space-between"
            @click="clickItem4"
          >
            <div class="name">Copyright</div>
            <v-icon class="ml-2" color="primary" v-if="clickCopyright"
              >mdi-menu-down</v-icon
            >
            <v-icon class="ml-2" color="primary" v-else>mdi-menu-up</v-icon>
          </div>
          <div v-if="!clickCopyright" class="labstatus">
            <div class="tokens">
              <div
                class="lab d-flex flex-row"
                v-for="(item, index) in CopyrightList"
                :key="index"
                :class="item.checked ? 'active' : ''"
                @click="CopyrightChange(item)"
              >
                <div class="chainname">{{ item.value }}</div>
              </div>
            </div>
          </div>
          <div class="line"></div>
        </div>

        <div>
          <div
            class="selectTitle d-flex justify-space-between"
            @click="clickItem5"
          >
            <div class="name">Collection</div>
            <v-icon class="ml-2" color="primary" v-if="clickCollection"
              >mdi-menu-down</v-icon
            >
            <v-icon class="ml-2" color="primary" v-else>mdi-menu-up</v-icon>
          </div>
          <div v-if="!clickCollection" class="collectionSearch">
            <v-text-field
              class="search-inpt"
              placeholder="Search by Collections"
              v-model="collectSearch"
              hide-details
              hide-spin-buttons
              clear-icon="mdi-close-circle"
              clearable
            >
              <template v-slot:prepend-inner
                ><img class="prepend-inner" src="@/assets/icons/search.png" alt
              /></template>
            </v-text-field>
            <div class="collectList d-flex flex-column">
              <div
                class="collectItem d-flex justify-space-between"
                :class="{ darkBackground: isShowClose }"
                v-for="(item, index) in collectlist"
                :key="index"
                @click="clickItem(item)"
              >
                <div class="left d-flex flex-row">
                  <img :src="item.imgSrc" alt="" />
                  <div class="text-area d-flex flex-column">
                    <div class="name-area d-flex flex-row">
                      <div class="name">
                        {{
                          item.name
                            ? item.name
                            : item.address.substr(0, 5) +
                              "..." +
                              item.address.substr(-5)
                        }}
                      </div>
                      <img
                        v-if="item.contractStatus == 1"
                        style="height: 20px; width: 20px; margin-left: 6px"
                        src="@/assets/icons/officially_verified.png"
                        alt=""
                      />
                    </div>

                    <div class="amount">{{ item.amount }}</div>
                  </div>
                </div>
                <div class="right">
                  <img
                    class="clo"
                    @click.stop="collectCloseDialog"
                    v-if="isShowClose"
                    src="@/assets/icons/btn_close.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="collect" v-if="!isShowFilter && !isMobile">
        <div
          v-if="nfts.length == 0 && !isShowLoading"
          class="empty d-flex flex-column"
        >
          <div class="notFound">No NFTs found</div>
          <div class="tryAnother">
            We couldn't find any NFTs matching your query. Try another query
          </div>
        </div>
        <div
          class="card"
          :class="{ bigCard: bigCard }"
          v-for="(item, index) in nfts"
          :key="index"
        >
          <Card
            type="other"
            :bigCard="bigCard"
            :hash="item.hash"
            :value="item"
            :selectType="activeSort2"
            owerfrom="resale"
            v-scroll="onScroll"
          />
        </div>
        <loading
          :isShowLoading="isShowLoading"
          style="padding-left: 500px"
        ></loading>
      </div>
      <div class="filterCollect" v-if="isShowFilter && !isMobile">
        <div
          v-if="nfts.length == 0 && !isShowLoading"
          class="empty d-flex flex-column"
        >
          <div class="notFound">No NFTs found</div>
          <div class="tryAnother">
            We couldn't find any NFTs matching your query. Try another query
          </div>
        </div>
        <div
          class="card"
          :class="{ smallView: smallsmallCard, bigCard: bigCard }"
          v-for="(item, index) in nfts"
          :key="index"
        >
          <Card
            type="other"
            :smallsmallCard="smallsmallCard"
            :filterBigCard="bigCard"
            :hash="item.hash"
            :value="item"
            :selectType="activeSort2"
            owerfrom="resale"
            v-scroll="onScroll"
          />
        </div>
        <loading
          :isShowLoading="isShowLoading"
          style="padding-left: 400px"
        ></loading>
      </div>
      <div class="collect" v-if="isMobile">
        <div class="card" v-for="(item, index) in nfts" :key="index">
          <Card
            type="other"
            :hash="item.hash"
            :value="item"
            owerfrom="resale"
            :selectType="activeSort2"
            v-scroll="onScroll"
          />
        </div>
      </div>
    </div>
    <div class="right_bottom" v-if="!isMobile">
      <img src="@/assets/icons/btn_play1.png" @click="openCarousel" alt="" />
    </div>
    <div class="right_bottom1" v-if="!isMobile">
      <img
        v-if="!openmsg_group"
        src="@/assets/image/btn_service-message.png"
        @click="openServiceMessage"
        alt=""
      />
    </div>
    <div
      v-if="openmsg_group"
      style="position: fixed; right: 20px; bottom: 20px; z-index: 9999"
    >
      <message-service
        @getOpen="OpenMsg_group"
        v-if="openmsg_group"
        :currfriend="currfriend_group"
      ></message-service>
    </div>
    <v-dialog v-model="opencard">
      <carousel
        class="otherpage"
        :list="nfts"
        v-if="opencard"
        @getOpen="OpenCard"
      ></carousel>
    </v-dialog>
    <uComponents ref="ucom"></uComponents>
  </div>
</template>

<script lang="js">
// import Cards from "@/views/home/components/Cards.vue";
import CardList from "@/components/workCard/CardList.vue";
import SortSelect from '@/components/MarketSortSelect.vue';
import api from "@/api"
import Carousel from "@/components/carousel";
import { mapState } from 'vuex'
import SearchInput from '@/components/SearchInput.vue'

import CollectCard from '@/components/workCard/CollectCard.vue';
import Card from "@/components/workCard/Card.vue";
import MessageService from "@/components/popup/messageService.vue";
import Loading from "@/components/loading.vue";
import store from '@/store';
import { getFileSrc } from "@/utils/file";
import { getSettingArr } from "@/utils/helper";
const WORK_KEY = "WORK";
let last_time = 0;
let collect_last_time = 0;
let qualification = 0;

export default {
  name: 'Marketplace',
  components: { CardList, SortSelect, Carousel, SearchInput, CollectCard, Card,MessageService,Loading },
  props: {},
  data: function () {
    return {
      isloadList:false,
      isShowClose:false,
      collectSearch:'',
      bigCard:false,
      smallsmallCard:true,
      minValue:'',
      maxValue:'',
      checkbox:false,
      collectlist:[],
      tokenList:[
        {
          key:1,
          value:'All',
          imgName:'',
          checked:true

        },
         {
          key:2,
          value:'ETH',
          imgName:'chain_eth_w',
          checked:false
        },
        {
          key:3,
          value:'LRC',
          imgName:'chain_lrc_w',
          checked:false
        }
      ],  
      statusList:[
         {
          key:'',
          value:'All',
           checked:true
        },
         {
          key:1,
          value:'Buy Now',
           checked:false
        },
        {
          key:2,
          value:'On Auction',
           checked:false
        }
      ],
       typeList:[
         {
          key:'',
          value:'All',
          checked:true
        },
        
         {
          key:2,
          value:'Multiple Editions',
           checked:false
        },
        {
          key:1,
          value:'Single Edition',
           checked:false
        },
        
      ],
      QualificationList:[
          {
          key:1,
          value:'Certified Contract',
          checked:false
        },
        //  {
        //   key:2,
        //   value:'Certified creators',
        //     checked:false
        // },
        // {
        //   key:4,
        //   value:'Verified creators',
        //   checked:false
        // },
      ],
      CopyrightList:[
         {
          key:'',
          value:'All',
           checked:true
        },
         {
          key:1,
          value:'CC0 NFTS',
           checked:false
        },
      ],
       inputTagList: [ {
          key:1,
          value:'All',
          imgName:'',
          checked:true

        },],
       statusTagList:[
          {
          key:'',
          value:'All',
           checked:true
        },
       ],
      typeTagList:[ {
          key:'',
          value:'All',
           checked:true
        },],
       QualificationTagList:[
         
       ],
       CopyrightTagList:[  {
          key:'',
          value:'All',
           checked:true
        },],
      clickTokens:false,
      clickStatus:false,
      clickType:false,
      clickQualification:false,
      clickCopyright:false,
      clickCollection:false,


      selectList2: [
        { value: 1, label: "Recently Created" },
        { value: 2, label: "Recently Listed" },
         { value: 3, label: "Recently Sold" },
        { value: 4, label: "Ending Soon" },
         { value: 8, label: "Most Likes" },
          { value: 7, label: "Most Viewed" },
        { value: 9, label: "Random" },
      ],
      activeSort2: sessionStorage.getItem('market_activeSort2') ?  Number(sessionStorage.getItem('market_activeSort2')) : 2,
      nfts: [],
      pageNumber: 1,
      pageSize: 20,
      totalPage: false,
      isShowLoading: false,
      showModel: "nft", // collection
      nftImage: require("@/assets/icons/icon_cj_nft.png"),
      collectionImage: require("@/assets/icons/icon_cj_collection_g.png"),
      opencard: false,
      rotate: false,
      currfriend_group:{},
      openmsg_group: false,
      searchInput:'',
      isShowFilter:true 
    };
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
    handler: function () {
      return {
        activeSort2: this.activeSort2,
        inputTagList:this.inputTagList,
        statusTagList:this.statusTagList,
        typeTagList:this.typeTagList,
        CopyrightTagList:this.CopyrightTagList,
        QualificationTagList:this.QualificationTagList,
      }
    },
   
    isShowClear:function () {

      if((this.inputTagList.length>0 &&this.inputTagList[0].value == 'All') && (this.statusTagList.length>0 &&this.statusTagList[0].value == 'All')
      && (this.typeTagList.length>0 &&this.typeTagList[0].value == 'All')   && (this.CopyrightTagList.length>0 &&this.CopyrightTagList[0].value == 'All' && !this.isShowClose) && this.QualificationTagList.length == 0 )
      {
        console.log("wxl ==  false")
        return false
      }else{
        console.log("wxl ==  true")
        return true
      }
      
    }
  

  },
   beforeRouteEnter(to, from, next) {
      console.log("wxl --- 11111",from.name)
      next(vm => {
        if (from.name === 'Saledetail' || from.name === 'Detail' ) {
          vm.nfts = [...store.state.NFTList];
          if (store.state.NFTListCursor) {
            vm.pageNumber = store.state.NFTListCursor.pageNumber;
            vm.pageSize = store.state.NFTListCursor.pageSize;
            vm.totalPage = store.state.NFTListCursor.totalPage;
          }
        } else {
          store.dispatch('setList')
        }
      });
    },
     beforeRouteLeave(to, from, next) {
       if (to.name === 'Saledetail' || to.name === 'Detail') {
          store.dispatch('setList', {
            NFTList: this.nfts,
            NFTListCursor: {
              pageNumber: this.pageNumber,
              pageSize: this.pageSize,
              totalPage: this.totalPage,
            }
          })
        } else {
          store.dispatch('setList')
        }
      next();
  },
  watch: {
    handler: {
      handler: function () {
        clearTimeout(last_time);
        last_time = setTimeout(() => {
          this.nfts = [];
          this.collects = []
          this.pageNumber = 1;
          this.getColleectList();
          
        }, 1000)
      },
      deep: true
    },
    collectSearch(val){       
        if(val == null) {
          this.collectSearch = '';
          this.isShowClose = false
        }
         clearTimeout(collect_last_time);
       
           collect_last_time = setTimeout(() => {
      
          this.getSelsetCollectList('');
                   
        }, 1000)
           
    },
    activeSort2(){
      // google分析 埋点
      switch (this.activeSort2) {
        case 1:
          this.$gtag.event('右侧排序-Recently Created', { 'event_category': 'Click', 'event_label': 'Recently Created' })
          break;
        case 2:
            this.$gtag.event('右侧排序-Recently Listed', { 'event_category': 'Click', 'event_label': 'Recently Listed' })
          break;
        case 3:
            this.$gtag.event('右侧排序-Recently Sold', { 'event_category': 'Click', 'event_label': 'Recently Sold' })
          break;
        case 4:
            this.$gtag.event('右侧排序-Ending Soon', { 'event_category': 'Click', 'event_label': 'Ending Soon' })
          break;
        case 5:
          this.$gtag.event('右侧排序-Price Low to High', { 'event_category': 'Click', 'event_label': 'Price Low to High' })
          break;
        case 6:
            this.$gtag.event('右侧排序-Price High to Low', { 'event_category': 'Click', 'event_label': 'Price High to Low' })
          break;
        case 7:
            this.$gtag.event('右侧排序-Most Viewed', { 'event_category': 'Click', 'event_label': 'Most Viewed' })
          break;
        case 8:
            this.$gtag.event('右侧排序-Most Likes', { 'event_category': 'Click', 'event_label': 'Most Likes' })
             break;
        case 9:
            this.$gtag.event('右侧排序-Random', { 'event_category': 'Click', 'event_label': 'Random' })
          break;
        default:
          break;
      }

    }
   
  },
  methods: {

    openServiceMessage(){
        this.currfriend_group.fansAddress = "0x91eB1DE0543a4AA41ac960E67c1ECF729AAEdf06";
        this.currfriend_group.fansName = "Uptick CS"
        this.openmsg_group = true;

    },
     OpenMsg_group(e) {
        this.openmsg_group = e;
      },
    loadChain() {
      let chainToken = localStorage.getItem("ChainToken");
      if(chainToken) {
          let coinData = JSON.parse(chainToken);
          coinData.forEach(ele => {
            this.selectList1.push({ value: ele.name, label: ele.name });
          });
      }
    },
    SearchChange(val) {
       this.$gtag.event('市场搜索', { 'event_category': 'Click', 'event_label': 'search' })
      this.searchInput = val
      clearTimeout(last_time);
      last_time = setTimeout(() => {
        this.nfts = [];
        this.collects = []
        this.pageNumber = 1;
        this.getColleectList();
      }, 600)
    },
    mode_click(mode) {
      this.showModel = mode;
      mode == "nft" ? this.nftImage = require("@/assets/icons/icon_cj_nft.png") : this.nftImage = require("@/assets/icons/icon_cj_nft_g.png");
      mode == "collection" ? this.collectionImage = require("@/assets/icons/icon_cj_collection.png") : this.collectionImage = require("@/assets/icons/icon_cj_collection_g.png");
      this.collects = []
      this.nfts = []
      this.getColleectList();
    },
    imgClick(item) {
      this.$router.push({ name: "CollectionPage", query: { tokenAddress: item.address } });
    },
    Apply(){
      if( Number(this.minValue) >=  Number(this.maxValue))
      {
          this.$toast("error", "Minimum must be less than maximum");
          this.minValue = '';
          this.maxValue = ''
          return
      }
       this.nfts = [];
        this.collects = []
        this.pageNumber = 1;
        this.getColleectList();

    },
    minValueInput(){
      console.log('wwww minValueInput',this.minValue)
      this.fontChange = true
    },
    refresh() {
      this.rotate = true;
      setTimeout(() => { this.rotate = false }, 2000);
      this.nfts = []
      this.pageNumber = 1;
      this.getColleectList();
      window.eventBus.$emit("clearSearch");
      // this.$emit("clearSearch"); 

    },
    async getColleectList() {  
      sessionStorage.setItem('market_activeSort2', this.activeSort2)
      console.log("wxl --- collectSearch",this.collectSearch)

       let collectAddress =  localStorage.getItem("collect_address");
  
      let params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        marketTokenType: this.inputTagList[0].value,
        search: encodeURIComponent(this.searchInput || ""),
        sort: this.activeSort2,
        nftId: '',
        tokenAddress: '',
        minPrice:this.minValue,
        maxPrice:this.maxValue,
        saleModel:this.statusTagList[0].key,
        nftShowType:this.typeTagList[0].key,
        qualification:qualification == 0?'':qualification,
        copyright:this.CopyrightTagList[0].key    
      };
      if(collectAddress){
        params.address = collectAddress
      }
      if( this.isloadList){
        return
      }
      this.isloadList = true
      this.isShowLoading = true;
      return api.home.getMarketList(params).then(res => {
        let { list, totalPage } = res.data;
        this.totalPage = totalPage;
        this.nfts = this.nfts.concat(list);
        this.isShowLoading = false;
        if (this.nfts.length > 0) {
          this.showRightBottom = true
        } else {
          this.showRightBottom = false
        }
         this.isloadList = false
      })
    },
    async onScroll(e) {
      const { scrollTop, clientHeight, scrollHeight } =
        e.target.scrollingElement;
      if (scrollTop + clientHeight === scrollHeight && (this.totalPage > this.pageNumber)) {
        this.pageNumber++;
        await this.getColleectList();
      }

    },
     //标签选中
         tagChange(item) {
           this.tokenList.forEach(v => {
             v.checked = false
             
           });
           item.checked = !item.checked
           this.inputTagList = this.tokenList.filter(v=>v.checked)
           if(this.inputTagList[0].value == 'ETH' || this.inputTagList[0].value == 'LRC'){
             if(this.selectList2.length == 7)
              this.selectList2.splice(4, 0, { value: 5, label: "Price low to high" },{ value: 6, label: "Price high to low" },);
           }else{
             this.activeSort2 = 2
              if(this.selectList2.length == 9)
             this.selectList2.splice(4,2)
           }
           this.$gtag.event('左侧导航栏-Price', { 'event_category': 'Click', 'event_label': 'Price' })
             sessionStorage.setItem('token_clicked', JSON.stringify(this.inputTagList))
         },
         statusChange(item){
           this.statusList.forEach(v => {
             v.checked = false
             
           });
             item.checked = !item.checked
             this.statusTagList = this.statusList.filter(v=>v.checked)
              this.$gtag.event('左侧导航栏-Status', { 'event_category': 'Click', 'event_label': 'Status' })
              sessionStorage.setItem('status_clicked', JSON.stringify(this.statusTagList))
         },
         typeChange(item){
            this.typeList.forEach(v => {
             v.checked = false
             
           });
              item.checked = !item.checked
              this.typeTagList = this.typeList.filter(v=>v.checked)
              this.$gtag.event('左侧导航栏-Type', { 'event_category': 'Click', 'event_label': 'Type' })
               sessionStorage.setItem('type_clicked', JSON.stringify(this.typeTagList))
         },
         QualificationChange(item,index){
           
           item.checked = !item.checked
           if( item.checked){
             this.QualificationTagList.push(item)
              qualification += item.key
               
           }else{
                 this.QualificationTagList.splice(index,1)
                  qualification -= item.key
                  
           }
            this.$gtag.event('左侧导航栏-Verification', { 'event_category': 'Click', 'event_label': 'Verification' })
           sessionStorage.setItem('qualify_clicked', JSON.stringify(this.QualificationTagList))  
           sessionStorage.setItem('qualify_number', qualification)  
             
      
         },
         CopyrightChange(item){
            this.CopyrightList.forEach(v => {
             v.checked = false
             
           });
               item.checked = !item.checked
              this.CopyrightTagList = this.CopyrightList.filter(v=>v.checked) 
              this.$gtag.event('左侧导航栏-Copyright', { 'event_category': 'Click', 'event_label': 'Copyright' })
              sessionStorage.setItem('Copyright_clicked', JSON.stringify(this.CopyrightTagList))       
         },
    openCarousel() {
      this.opencard = true;
    },
    OpenCard(e) {
      this.opencard = e;
    },
    clickToken(){
      this.clickTokens= !this.clickTokens
    },
    clickItem1(){
      this.clickStatus= !this.clickStatus
    },
    clickItem2(){
      this.clickType= !this.clickType
    },
      clickItem3(){
      this.clickQualification= !this.clickQualification
    },
     clickItem4(){
      this.clickCopyright= !this.clickCopyright
    },
     clickItem5(){
      this.clickCollection= !this.clickCollection
    },
    
    showFilter(){
      this.isShowFilter = !this.isShowFilter
    },
    clearAll(){
      
      console.log("wxl --- clearAll",this.tokenList)
      //清除tokens列表
      this.tokenList.forEach((v)=>{
        v.checked = false
      })
      this.tokenList[0].checked = true
      this.inputTagList = this.tokenList.filter(v=>v.checked)
      //清除status列表
       this.statusList.forEach((v)=>{
        v.checked = false
      })
      this.statusList[0].checked = true
      this.statusTagList = this.statusList.filter(v=>v.checked)
      //清除type列表
       this.typeList.forEach((v)=>{
        v.checked = false
      })
      this.typeList[0].checked = true
      this.typeTagList = this.typeList.filter(v=>v.checked)
      //清除copyright列表
       this.CopyrightList.forEach((v)=>{
        v.checked = false
      })
      this.CopyrightList[0].checked = true
      this.CopyrightTagList = this.CopyrightList.filter(v=>v.checked)

      //清楚集合列表
      this.collectCloseDialog();
       if(this.selectList2.length == 9)
             this.selectList2.splice(4,2)
      
      this.activeSort2 =2
      // clear 清理缓冲
       sessionStorage.setItem('token_clicked', JSON.stringify([{
          key:1,
          value:'All',
          imgName:'',
          checked:true

        }] ,))

       sessionStorage.setItem('status_clicked', JSON.stringify([{
          key:'',
          value:'All',
           checked:true
        }] ,))
           sessionStorage.setItem('type_clicked', JSON.stringify([{
          key:'',
          value:'All',
          checked:true
        }] ,))

          sessionStorage.setItem('Copyright_clicked', JSON.stringify([{
          key:'',
          value:'All',
           checked:true
        }] ,))
           sessionStorage.setItem('qualify_clicked', JSON.stringify([{ 
          key:1,
          value:'Certified Contract',
          checked:false
        }],))
     sessionStorage.setItem('qualify_number',0)  

      this.QualificationTagList.forEach(v => {
      if(v.checked)
         v.checked = false     
       });
       this.QualificationTagList = []
       qualification = 0
      this.minValue = ''
      this.maxValue = ''
    },

    changeSmallView(){
       this.$gtag.event('市场小视图', { 'event_category': 'Click', 'event_label': 'smallView' })
        this.bigCard = false
      this.smallsmallCard = true

    },
    changeBigView(){
       this.$gtag.event('市场大视图', { 'event_category': 'Click', 'event_label': 'bigView' })
      this.bigCard = true
       this.smallsmallCard = false

    },
    closeDialog(){
      this.clearAll()
    },
    collectCloseDialog(){
      localStorage.setItem("collect_address",'');
      console.log('collectCloseDialog')
      this.isShowClose = false
      this.collectSearch = ''
       this.getSelsetCollectList('')
         this.nfts = [];
        this.collects = []
        this.pageNumber = 1;
       this.getColleectList();

    },
   async clickItem(item){
       localStorage.setItem("collect_address",item.address );
        this.$gtag.event('左侧导航栏-collect', { 'event_category': 'Click', 'event_label': 'collect' })

       // 获取筛选集合列表
     await this.getSelsetCollectList(item.address)
      this.isShowClose = true

     // 获取集合NFT列表
        this.nfts = [];
        this.collects = []
        this.pageNumber = 1;
      
      this.getColleectList();
      

     
    },
   async getSelsetCollectList(address){
    //  if(address == ''){
    //    this.isShowClose = false
    //  }
      let params = {
       pageNumber:1,
        pageSize:5,
        nftShowType:3,
        address:address,
        search: encodeURIComponent(this.collectSearch|| ""),
      }
      
     let result = await api.home.getMarketList(params)
    this.collectlist = []
      let collectlist = result.data.list
      collectlist.forEach( async e => {
         let imgSrc = await getFileSrc(WORK_KEY,e.imgUrl)
        this.collectlist.push({
          imgSrc:imgSrc,
          name:e.name,
          amount:e.nftCount,
          address:e.address,
          tokenAddress:e.tokenAddress,
          linkUrl:e.linkUrl,
          owner:e.owner,
          contractStatus:e.contractStatus
        })
        
        
      });
      console.log('wxl ---- this.collectlist',this.collectlist)

    },
    // async getSelectNftList(item){
    //     this.nfts = [];
    //     this.collects = []
    //     this.pageNumber = 1;

    //    let params = {
    //      pageNumber: this.pageNumber,
    //      pageSize: this.pageSize,
    //      tokenAddress: item.address,
    //      linkUrl: item.linkUrl,
    //      sourceType: 2,
    //      owner:item.owner ,
    //    };
  
    //     let res = await api.home.getMarketnftList(params);
    //       let { list, totalPage } = res.data;
    //     this.totalPage = totalPage;
    //     this.nfts = this.nfts.concat(list);
    //     this.isShowLoading = false;
    //     if (this.nfts.length > 0) {
    //       this.showRightBottom = true
    //     } else {
    //       this.showRightBottom = false
    //     }

    // },
    onLangChange() {
      this.selectList = [
        { value: 1, label: this.$t('marketType1') },
        { value: 2, label: this.$t('marketType2') },
        { value: 3, label: this.$t('marketType3') },
        { value: 4, label: this.$t('marketType4') },
        { value: 8, label: this.$t('marketType8') },
        { value: 9, label: this.$t('marketType9') },
        { value: 5, label: this.$t('marketType5') },
        { value: 6, label: this.$t('marketType6') },
      ];
      this.isRefsh++;
    },
     async showNoRead(){
    const json = localStorage.getItem("key_user");
    if (json) {
      let res2 = await api.home.isNewMessage({userAddress:this.$store.state.did,pushTypes:getSettingArr()});
      let newMsgCount = res2.data;


     // 有新消息进行弹窗
     if(newMsgCount >0){
        let params={
        pageSize: 10,
        pageNumber: 1,
        owner:this.$store.state.did,
        isRead:false,
        pushTypes:getSettingArr()
      };
      let res = await api.home.sysMessage(params)
      console.log("wxl --- sysMessage",res)
      if(res.data){
         let list = res.data.list
         for (let index = 0; index < list.length; index++) {
           setTimeout(() => {
              this.$mtip({
                title:list[index].pushContent,
              });
           }, 1000);
           
         }
      }      
    }
   }
   window.eventBus.$emit("ViewMessage", true);
   },
   init(){
     //  token
      let tokenStr = sessionStorage.getItem('token_clicked')
      let tokenName ,token
    if(tokenStr){
     token = JSON.parse(tokenStr)
      tokenName = token[0].value 
    }else{
      tokenName = 'All'
    } 
      this.tokenList.forEach(v => {
            v.checked = false            
      });
    for (let index = 0; index < this.tokenList.length; index++) {
      if( tokenName ==  this.tokenList[index].value )
        this.tokenList[index].checked = true      
    }

    if(tokenName == 'LRC' ||  tokenName == 'ETH'){
       if(this.selectList2.length == 7){
              this.selectList2.splice(4, 0, { value: 5, label: "Price low to high" },{ value: 6, label: "Price high to low" },);
           }
    }else{
            
              if(this.selectList2.length == 9)
             this.selectList2.splice(4,2)
    }
    this.inputTagList = this.tokenList.filter(v=>v.checked)
    
    // status
      let statusStr = sessionStorage.getItem('status_clicked')
      let statusName ,statustoken
    if(statusStr){
     statustoken = JSON.parse(statusStr)
      statusName = statustoken[0].value 
    }else{
      statusName = 'All'
    } 
      this.statusList.forEach(v => {
            v.checked = false            
      });
    for (let index = 0; index < this.statusList.length; index++) {
      if( statusName ==  this.statusList[index].value )
        this.statusList[index].checked = true      
    }
    this.statusTagList = this.statusList.filter(v=>v.checked)

    // type
    let typeStr = sessionStorage.getItem('type_clicked')
      let typeName ,typetoken
    if(typeStr){
     typetoken = JSON.parse(typeStr)
      typeName = typetoken[0].value 
    }else{
      typeName = 'All'
    } 
      this.typeList.forEach(v => {
            v.checked = false            
      });
    for (let index = 0; index < this.typeList.length; index++) {
      if( typeName ==  this.typeList[index].value )
        this.typeList[index].checked = true      
    }
    this.typeTagList = this.typeList.filter(v=>v.checked)

    //Copyright
      let CopyrightStr = sessionStorage.getItem('Copyright_clicked')
      let CopyrightName ,Copyrighttoken
    if(CopyrightStr){
     Copyrighttoken = JSON.parse(CopyrightStr)
      CopyrightName = Copyrighttoken[0].value 
    }else{
      CopyrightName = 'All'
    } 
      this.CopyrightList.forEach(v => {
            v.checked = false            
      });
    for (let index = 0; index < this.CopyrightList.length; index++) {
      if( CopyrightName ==  this.CopyrightList[index].value )
        this.CopyrightList[index].checked = true      
    }
    this.CopyrightTagList = this.CopyrightList.filter(v=>v.checked)

    //QualificationTagList
     qualification = 0
     debugger
       let qualifyStr = sessionStorage.getItem('qualify_clicked');
       
       let qualifyNumber = sessionStorage.getItem('qualify_number')  
       if(qualifyNumber){
           qualification = Number(qualifyNumber)  
       }
     
      let qualifyName ,qualifytoken
    if(qualifyStr){
     qualifytoken = JSON.parse(qualifyStr)
      qualifyName = qualifytoken[0].value 
      this.QualificationList = qualifytoken
    }else{
      qualifyName = ''
      // this.QualificationTagList.push ({ 
      //     key:1,
      //     value:'Certified Contract',
      //     checked:false
      //   })
    console.log("wxl ---- eeeee", this.QualificationTagList.length)
     
    } 
   
 //   this.QualificationList.forEach(v => {
    //         v.checked = false            
    //   });
    // for (let index = 0; index < this.QualificationList.length; index++) {
    //   if( qualifyName ==  this.QualificationList[index].value )
    //     this.QualificationList[index].checked = true    
    // }
    // this.QualificationTagList = this.QualificationList.filter(v=>v.checked)
   }
  },
   
  mounted() {
    this.init();
   
    this.showNoRead();
    window.eventBus.$on('LangChange', this.onLangChange);
    localStorage.setItem("collect_address",'');
   
    
    // this.activeSort1 = ''
    // this.activeSort2 = 2;
     if (this.nfts.length === 0) {
        this.getColleectList()
    }
     this.getSelsetCollectList('');
 
    this.loadChain();
  },
};
</script>

<style lang="scss" scoped>
.top {
  display: flex;
  align-items: center;
  position: sticky;
  top: 78px;
  z-index: 20;
  height: 41px;
  background-color: rgb(255, 255, 255);
  margin-top: -10px !important;

  .filter_btn {
    width: 80px;
    height: 41px;
    background-color: #f0f0f0;
    border-radius: 20px;
    text-align: center;
    .filter {
      font-family: Helvetica;
      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
    }
  }
  .clean_btn {
    width: 108px;
    height: 40px;
    background-color: #ffffff;
    border-radius: 20px;
    border: solid 1px #e3e3e3;
    margin-left: 30px;
    .ClearAll {
      font-family: Helvetica;
      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      margin-left: 21px;
    }
  }
  .clo {
    position: relative;
    top: 2px;
    left: -97px;
    width: 15px;
    height: 15px;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
    border-radius: 50% !important;
    z-index: 99;
  }
  .search {
    width: 590px;
    margin-top: -12px;
  }
  .refresh {
    height: 18px;
    width: 18px;
    margin-right: 15px;
    margin-top: 10px;
  }

  .top-right {
    ::v-deep .sort-select .rd.v-chip {
      box-shadow: none;
      border: none;
      margin-bottom: 2px;
      font-weight: bold;
    }
    .nft {
      margin-left: 20px;
      width: 22px;
      height: 28px;
    }

    .collection {
      width: 25px;
      height: 25px;
      margin-left: 30px;
    }
    .smallView_btn {
      width: 55px;
      height: 41px;
      background-color: #f0f0f0;
      border-radius: 0px 20px 20px 0px;
      &:hover {
        background-color: #f7f7f7;
      }
    }
    .smallView_btn_back {
      background-color: #f7f7f7;
      &:hover {
        background-color: #f7f7f7;
      }
    }
    .bigView_btn {
      width: 55px;
      height: 41px;
      background-color: #f7f7f7;
      border-radius: 20px 0px 0px 20px;
    }
    .bigView_btn_back {
      background-color: #f0f0f0;
      &:hover {
        background-color: #f7f7f7;
      }
    }
  }
}
.right_bottom {
  position: fixed;
  bottom: 180px;
  right: 50px;
  z-index: 200;

  img {
    display: block;
    width: 59px;
    height: 59px;
    margin-top: 35px;
  }
}
.right_bottom1 {
  position: fixed;
  bottom: 100px;
  right: 50px;
  z-index: 200;

  img {
    display: block;
    width: 59px;
    height: 59px;
    margin-top: 35px;
  }
}
.main {
  height: 100%;
  margin: 5px 0;
  .sort {
    position: -webkit-sticky;
    position: sticky;
    top: 90px;
    height: calc(100vh - 138px);
    width: 245px;
    overflow: hidden;
    margin-top: 15px;
    .line {
      margin-top: 15px;
      margin-bottom: 15px;
      width: 235px;
      background-color: #ffffff;
      border: solid 0.3px #eeeeee;
    }
  }
  .sort:hover {
    overflow-y: auto;
  }
  .sort::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    background-color: white !important; /*(透明)*/
  }
  .sort::-webkit-scrollbar-thumb {
    background-color: #dedede !important;
  }

  .selectTitle {
    cursor: pointer;
    font-family: Helvetica;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;

    letter-spacing: 1px;
    color: #270645;
    .name {
      font-family: Helvetica;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
    }
    // .icon{
    //   // &::after{
    //   //   width: 0px !important;
    //   //   height: 0px !important;
    //   // }
    //   &::v-deep .v-icon::after{
    //        width: 0px !important;
    //      height: 0px !important;
    //   }
    // }
    .v-icon::after {
      height: 0px !important;
    }
  }
  .labstatus {
    margin-top: 20px;
    .tokens {
      text-decoration: none;
      display: flex;
      flex-wrap: wrap;
      .lab {
        cursor: pointer;
        border-radius: 20px;
        background-color: #f0f0f0;
        margin-right: 10px;
        margin-bottom: 9px;
        width: auto;
        min-width: 60px;
        height: 35px;
        padding: 0 16px;

        line-height: 35px;
        text-align: center;
        &:hover {
          background-color: #e0e0e0;
        }

        .chainname {
          font-family: Helvetica;
          font-size: 13px;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #270645;
          margin-left: 5px;
        }

        .chainnames {
          font-family: Helvetica;
          font-size: 13px;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #270645;
        }
      }
      .active {
        background: #270645;
        &:hover {
          background-color: #270645;
        }
        .chainname {
          color: floralwhite;
        }
        .chainnames {
          color: floralwhite;
        }
      }
    }
    .tokenPrice {
      .title {
        font-family: Helvetica;
        font-size: 13px !important;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #270645;
      }
      .priceSelect {
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
        /* 在Firefox浏览器下 */
        input[type="number"] {
          -moz-appearance: textfield;
        }
        input::-webkit-input-placeholder {
          /* placeholder颜色 */
          color: #766983;
          /* placeholder字体大小 */
          font-size: 12px;
        }
        .to {
          font-family: Helvetica;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #766983;
          margin-top: 5px;
        }

        input {
          height: 35px;
          background-color: #f0f0f0;
          border-radius: 10px;
          font-family: Helvetica;
          font-size: 13px;
          color: rgba(0, 0, 0, 0.78);
          text-align: right;
          width: 70px;
          text-align: center;

          &:focus {
            outline: none;
          }
        }
        .apply {
          width: 80px;
          height: 35px;
          background-color: #f0f0f0;
          border-radius: 20px;
          font-family: Helvetica;
          font-size: 13px;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #270645;
        }
        .disable {
          color: #d2d2d2;
        }
      }
    }
  }
  .collectionSearch {
    .search-inpt {
      align-self: center;
      width: 230px;

      .prepend-inner {
        width: 17px;
        height: auto;
      }

      &.v-text-field.v-input--is-focused
        > .v-input__control
        > .v-input__slot:after {
        content: none !important;
      }

      .v-input__control {
        .v-input__slot::before {
          content: none;
        }
      }

      .v-input__slot {
        height: 40px;
        align-items: center;
        background-color: #f0f0f0;
        border-radius: 20px;
        outline-color: #f0f0f0;

        .v-input__prepend-inner {
          align-self: center;
          margin-top: 0;
          margin-left: 14px;
          margin-right: 13px;
          padding-right: 0;
        }
        .v-input__icon {
          margin-top: 4px !important;
          margin-right: 10px !important;
        }

        .v-text-field__slot {
          flex: 1;
          padding-right: 20px;
          width: unset;

          input {
            width: auto;
          }
        }
      }
    }
    .collectList {
      margin-top: 10px;
      .collectItem {
        width: 230px;
        height: 55px;
        cursor: pointer;

        &:hover {
          background-color: #f0f0f0;
          border-radius: 5px;
        }

        .left {
          margin: 7px;
          img {
            object-fit: cover;
            width: 40px;
            height: 41px;

            border-radius: 5px;
          }
          .text-area {
            margin-left: 12px;
            .name-area {
              .name {
                max-width: 100px;
                font-family: Helvetica;
                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                letter-spacing: 0px;
                color: #270645;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }

            .amount {
              height: 11px;
              font-family: Helvetica;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              letter-spacing: 0px;
              color: #8f7e9e;
            }
          }
        }
        .right {
          display: flex;
          text-align: center;
          align-self: center;
          .clo {
            // display: none;
            width: 15px;
            height: 15px;
            margin-right: 10px;
          }
        }
        // &:hover{

        // .clo{
        //   display: unset;
        // }
        // }
      }
      .darkBackground {
        background-color: #f0f0f0 !important;
        border-radius: 5px !important;
      }
    }
  }
  .checkBoxSelect {
    margin-top: 20px;
    .lab {
      text-align: center;
      height: 30px;
      margin-left: 2px;
      .checkbox {
        margin-top: -4px;
        &::v-deep .v-input--selection-controls__ripple {
          width: 0px !important;
          height: 0px !important;
        }
      }
      .name {
        font-family: Helvetica;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #270645;
      }
    }
  }

  .collect {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    margin-left: 15px;
    // justify-content: space-between;
    .empty {
      width: 100%;
      height: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
      .notFound {
        font-family: Helvetica;
        font-size: 24px;
        font-weight: bold;
      }
      .tryAnother {
        font-family: Helvetica;
        font-size: 18px;
        width: 300px;
        text-align: center;
      }
    }
    .card {
      margin-top: 16px;
      margin-bottom: 8px;
      margin-right: 24px;

      // justify-content: space-between;
      &:nth-child(5n) {
        margin-right: 0;
      }
    }
    .bigCard {
      margin-top: 7px !important;
      margin-bottom: 8px !important;
      margin-right: 12px !important;

      // justify-content: space-between;
      &:nth-child(4n) {
        margin-right: 0 !important;
      }
    }
  }
  .filterCollect {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    margin-left: 15px;
    // justify-content: space-between;
    .empty {
      width: 100%;
      height: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
      .notFound {
        font-family: Helvetica;
        font-size: 24px;
        font-weight: bold;
      }
      .tryAnother {
        font-family: Helvetica;
        font-size: 18px;
        width: 300px;
        text-align: center;
      }
    }

    .card {
      margin-top: 16px;
      margin-bottom: 8px;
      margin-right: 20px;

      // justify-content: space-between;
      &:nth-child(4n) {
        margin-right: 0;
      }
    }
    .bigCard {
      margin-top: 16px !important;
      margin-bottom: 8px !important;
      margin-right: 24px !important;

      // justify-content: space-between;
      &:nth-child(3n) {
        margin-right: 0 !important;
      }
    }
    .smallView {
      margin-top: 16px !important;
      margin-bottom: 8px !important;
      margin-right: 20px !important;

      // justify-content: space-between;
      &:nth-child(4n) {
        margin-right: 0 !important;
      }
    }
  }
}

.resale.mobile {
  .top {
    display: flex;
    align-items: center;
    height: 50px;

    .search {
      margin-bottom: unset !important;
      width: 260px !important;
      margin-top: -10px !important;
    }

    .top-right {
      width: unset;
      position: unset;
      margin-top: 16px;
    }

    .collection {
      margin-left: 20px;
    }

    // .refresh{
    //   height: 25px;
    //   width: 25px;
    //   margin-left: 15px;
    //   margin-right: 15px;
    // }
  }

  .collect {
    display: flex;
    justify-content: space-between;
    width: 330px;
    .card {
      width: 46%;
      margin-right: inherit;
    }
  }
}
</style>


